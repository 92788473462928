<template>
    <header class="sticky top-0 backdrop-filter backdrop-blur-[32px] bg-[#f6f7f8] text-neutral-300 bg-opacity-90 z-10" :class="scrolled ? 'border-2 border-[#e4e6e8]':''">
        <div class="container py-3">
            <div class="flex items-center">
                <img src="@/assets/images/common/tinkoff-logo-text.svg" alt="LOGO" class="h-9">
                <div class="flex-grow">
                    <slot/>
                </div>
                <a :href="'tel:'+phone" class="no-underline text-right">{{ phone }}</a>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    props: ['phone'],
    data: () => ({
        scrolled: false,
    }),

    created() {
        window.addEventListener('scroll', this.handleScroll);
    },

    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            this.scrolled = (window.scrollY > 0);
        }
    }
}
</script>

<style lang="scss" scoped>
header {
    line-height: 1;
}

.phone {
    text-decoration: none;
}

.header-container {
    padding-top: 13px;
    padding-bottom: 13px;
}
</style>