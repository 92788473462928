<template>
  <div class="grid md:grid-cols-3">
    <div v-for="item in items" :key="item.icon" class="step-item">
      <div class="item-icon" :class="iconClass">
        <div class="item-icon-content" v-html="item.icon"></div>
      </div>
      <div class="item-text" v-html="item.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "steps",
  props: {
    iconClass: {
      default: 'bg-gray-400 text-white'
    },
    items: {},
  }
}
</script>

<style scoped>
.step-item {
  text-align: center;
  padding: 30px;
}

.item-icon {
  font-size: 30px;
  font-family: TinkoffSans, sans-serif;
  width: 64px;
  height: 64px;
  margin: 0 auto 40px auto;
  border-radius: 20px;
  position: relative;
}

.item-icon-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-height: 64px;
  width: 64px;
}

.step-item::before {
  display: block;
  position: relative;
  content: ' ';
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: calc(100% - 40px);
  top: 32px;
  left: calc(50% + 50px);
}

.step-item:last-of-type::before {
  content: none;
}

@media screen and (max-width: 575px) {
  .step-item::before {
    content: none;
  }
}

</style>