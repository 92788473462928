<template>
  <site-header-v2 :phone="phone"/>
  <gradient-delimiter height="32px" start-color="#e6e6e7" end-color="#f6f7f8"/>
  <div class="bg-[#f6f7f8]">
    <div class="container">
      <h1 class="pt-16 md:w-2/3 text-center mx-auto">Заполните анкету за 5 минут и получите дебетовую карту</h1>
      <steps
          :items="[
          { icon:'1', text:'Заполните онлайн-заявку — <br>посещать банк не нужно'},
          { icon:'2', text:'Получите карту<br>с бесплатной доставкой'},
          { icon:'3', text:'Бесплатно пополните карту<br>с любой карты другого банка или наличными'}
          ]"
          icon-class="bg-gray-200 text-gray-500"
          class="mt-4"
      />
    </div>

    <app-form :url="form.url" />

    <div class="container">
      <h2 class="text-center mt-24 mb-10">Зарабатывайте и экономьте с картой<br>Black от Т-Банка</h2>
      <div class="grid md:grid-cols-3 gap-12">
        <div class="bg-[#333333] text-white p-8 rounded-3xl">
          <img src="@/assets/images/i1.svg" alt="">
          <h3 class="mt-8">Обслуживание 0 ₽</h3>
          <div class="mt-4">
            Если хранить от 50 000 ₽ на карте, вкладах, накопительных счетах, инвестициях. Иначе — 99 ₽ в месяц
          </div>
        </div>

        <div class="bg-[#333333] text-white p-8 rounded-3xl">
          <img src="@/assets/images/i2.svg" alt="">
          <h3 class="mt-8">До 30%</h3>
          <div class="mt-4">
            Кэшбэк до 30% у партнеров, до 15% в выбранных местах и категориях
          </div>
        </div>

        <div class="bg-[#eaecee] text-[#2f2f30] p-8 rounded-3xl">
          <img src="@/assets/images/i3.svg" alt="">
          <h3 class="mt-8">0 ₽</h3>
          <div class="mt-4">
            Снятие наличных во всех банкоматах
          </div>
        </div>
      </div>

      <div class="grid md:grid-cols-3 gap-12 mt-12">
        <div class="bg-[#eaecee] rounded-3xl col-span-2 overflow-hidden">
          <img src="@/assets/images/bann.webp" alt="">
        </div>

        <div>
          <h3 class="mt-6">100+ категорий кэшбэка в приложении</h3>
          <div class="mt-4">
            Снятие наличных во всех банкоматах
          </div>

          <h3 class="mt-12">Бесплатное пополнение</h3>
          <div class="mt-4">
            Пополняйте без комиссии с карт других банков, наличными в банкоматах Т-Банка <br> и до 150 000 ₽ в месяц у партнеров банка
          </div>

          <h3 class="mt-12">Снятие без комиссии</h3>
          <div class="mt-4">
            До 500 000 ₽ в банкоматах Т-Банка, от 3000 ₽ до 100 000 ₽ в других банкоматах
          </div>
        </div>
      </div>

      <div class="grid md:grid-cols-3 gap-12 mt-12">
        <div class="bg-[#eaecee] text-[#2f2f30] p-8 rounded-3xl">
          <img src="@/assets/images/o1.svg" alt="">
          <h3 class="mt-8">Бесплатные переводы на карты других банков</h3>
          <div class="mt-4">
            Переводите без комиссии на карты других банков через Систему быстрых платежей
          </div>
        </div>

        <div class="bg-[#eaecee] text-[#2f2f30] p-8 rounded-3xl">
          <img src="@/assets/images/o2.svg" alt="">
          <h3 class="mt-8">Кэшбэк рублями, а не бонусами</h3>
          <div class="mt-4">
            До 30% у партнеров, до 15% в выбранных местах и категориях
          </div>
        </div>

        <div class="bg-[#333333] text-white p-8 rounded-3xl">
          <img src="@/assets/images/o3.svg" alt="">
          <h3 class="mt-8">5% годовых по накопительному счету</h3>
          <div class="mt-4">
            Доход по накопительному счету<br>
            с подпиской Pro. Оформите<br>
            в личном кабинете или мобильном приложении
          </div>
        </div>
      </div>

      <div class="text-center">
        <yellow-button @click="gotoForm()" class="mt-12 mb-24">Оформить карту</yellow-button>
      </div>

    </div>
  </div>
  <site-footer :phone="phone" disclaimer="" copyright="" bank-links="" :product-links="pLinks"></site-footer>
</template>

<script>

import '@/assets/tailwind.css';
import GradientDelimiter from "@/components/gradient-delimiter";
import SiteFooter from "@/components/site-footer-v2";
import Steps from "@/components/steps.vue";
import AppForm from "@/components/app-form.vue";
import YellowButton from "@/components/yellow-button.vue";
import SiteHeaderV2 from "@/components/site-header-v2.vue";

export default {
  name: 'App',
  components: {SiteHeaderV2, YellowButton, AppForm, Steps, GradientDelimiter, SiteFooter},

  data: () => ({
    phone: process.env.VUE_APP_PHONE,
    form: {
      url: 'https://www.tbank.ru/cards/debit-cards/tinkoff-black/iframe/form/',
    },
    pLinks: [
      { text:'Правила программы лояльности', link:'https://acdn.tinkoff.ru/static/documents/loyalty-program-black.pdf' },
      { text:'Тарифный план', link:'https://acdn.tinkoff.ru/static/documents/debit-tariffs-tps_3_0.pdf' },
      { text:'«Т-Банк Таргет»', link:'https://acdn.tinkoff.ru/static/documents/loyalty-program-target_rules.pdf' },
      { text:'Курсы валют', link:'https://www.tinkoff.ru/about/exchange/' },
      { text:'Условия подписки', link:'https://acdn.tinkoff.ru/static/documents/docs-tinkoff-bundle-subscription-terms.pdf' },
    ]
  }),

  methods: {
    gotoForm() {
      window.scrollTo({
        top: document.getElementById('form-iframe').offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }
}

</script>

<style lang="scss">

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans.woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'haas';
  src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("assets/fonts/fa-regular-400.eot");
  src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("assets/fonts/fa-solid-900.eot");
  src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 15px;
  font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  color: #333333;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
}

.container {
  @media screen and (max-width: 575px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

h1, h2, h3 {
  font-family: 'TinkoffSans', sans-serif;
  font-weight: 400;
}

h1, h2 {
  font-weight: 700;
  font-variant-ligatures: no-contextual;
  font-size: 44px;
  line-height: 48px;
  letter-spacing: 0;
  @media screen and (max-width: 575px) {
    font-size: 32px;
    line-height: 35px;
  }
}

h2 {
  @media screen and (max-width: 575px) {
    font-size: 28px;
    line-height: 32px;
  }
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

.header {
  @apply mt-24 w-2/3 mx-auto text-center;
}

::selection {
  background: rgba(255, 221, 45, .32) !important;
  color: rgba(0, 0, 0, .8) !important;
}

.numbers {
  font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 100;
}

</style>
