<template>
    <footer>
        <div class="bg-white py-4">
            <div class="container">
                <div class="md:flex">
                    <div class="self-center logo">
                        <img src="@/assets/images/common/tinkoff-logo-text.svg" alt="LOGO" class="hidden md:block h-9">
                        <img src="@/assets/images/common/tinkoff-logo.svg" alt="LOGO" class="md:hidden block mx-auto h-16">
                    </div>
                    <div v-if="phone" class="self-center text-center md:text-right mt-8 md:mt-0" style="flex: auto">
                        <div class="phone">{{ phone }}</div>
                        <!--            <span class="call-free">Для звонков по России</span>-->
                    </div>
                    <!--          <div class="self-center justify-center social md:hidden flex mt-4">-->
                    <!--            <a v-for="soc in socials" :key="soc.link" :href="soc.link" target="_blank" v-html="soc.pic"/>-->
                    <!--          </div>-->
                </div>
            </div>
        </div>

        <div class="links" v-if="bankLinks || productLinks">
            <div class="container">
                <div class="my-4">
                    <div>
                        <div>
                            <slot/>
                        </div>
                        <div class="tks-links md:px-0 px-2">
                            <a v-for="link in bankLinks" :key="link.text" :href="link.link" class="inline-block">{{ link.text }}</a>
                        </div>
                        <div class="product-links md:px-0 px-2">
                            <a v-for="link in productLinks" :key="link.text" :href="link.link" target="_blank" class="inline-block">{{ link.text }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container" v-if="disclaimer">
            <div class="bottom py-4 flex md:px-0 px-2">
                <div class="self-center" v-html="disclaimer"/>
            </div>
        </div>

        <!--    <div class="container">-->
        <!--      <div class="bottom py-4 flex md:px-0 px-2">-->
        <!--        <div class="self-center flex-grow">-->
        <!--          <div v-if="copyright" v-html="'© 2006–'+year+', '+copyright"/>-->
        <!--        </div>-->
        <!--        <div class="self-center text-right social hidden md:flex">-->
        <!--          <a v-for="soc in socials" :key="soc.link" :href="soc.link" target="_blank" v-html="soc.pic"/>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->
    </footer>
</template>

<script>
export default {
    name: "tks-footer",

    data: () => ({
        socials: [
            {
                link: 'https://vk.com/tinkoffbank',
                pic: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24"><path d="M17.77 13.358c.531.573 1.091 1.111 1.567 1.741.21.28.41.569.562.894.216.462.02.97-.355.998l-2.33-.002c-.602.056-1.081-.212-1.485-.666-.322-.363-.621-.75-.932-1.124a2.296 2.296 0 00-.42-.412c-.317-.228-.593-.159-.775.208-.186.373-.228.786-.246 1.201-.025.606-.19.766-.742.793-1.178.062-2.296-.135-3.335-.792-.916-.578-1.626-1.395-2.245-2.32-1.203-1.801-2.124-3.78-2.953-5.815-.187-.458-.051-.704.408-.712a55.95 55.95 0 012.281-.002c.309.005.513.2.633.523.411 1.117.914 2.18 1.546 3.166.169.262.34.524.585.709.27.204.476.136.603-.196.08-.211.116-.438.134-.664.06-.777.068-1.553-.037-2.327-.065-.483-.312-.796-.748-.887-.223-.047-.19-.139-.082-.279.188-.242.364-.393.716-.393h2.634c.415.09.507.296.564.757l.002 3.232c-.005.178.08.708.372.826.232.084.386-.122.526-.285.63-.74 1.08-1.613 1.483-2.518.178-.398.332-.81.48-1.224.11-.306.283-.457.596-.45l2.535.003c.075 0 .151 0 .224.014.428.08.545.284.413.745-.208.723-.613 1.325-1.008 1.93-.423.646-.875 1.27-1.295 1.92-.385.594-.354.893.124 1.408z" fill="currentColor"></path></svg>',
            },
//      {
//        link: 'https://www.facebook.com/tinkoffbank',
//        pic: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24"><path d="M15.687 5h-1.841c-2.236 0-3.68 1.392-3.68 3.547v1.378H8.313c-.173 0-.313.132-.313.295v2.37c0 .163.14.294.313.294h1.853v5.823c0 .162.14.294.312.294h2.61c.174 0 .314-.132.314-.294v-5.824h2.284c.173 0 .313-.131.313-.294L16 10.22a.286.286 0 00-.092-.207.323.323 0 00-.221-.087h-2.285v-1.13c0-.666.169-1.004 1.093-1.004l1.192-.004c.172 0 .312-.131.312-.294v-2.2C16 5.132 15.86 5 15.687 5z" fill="currentColor"></path></svg>',
//      },
            {
                link: 'https://ok.ru/tinkoffbank',
                pic: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24"><path d="M11.18 15.341c-1.29-.127-2.455-.428-3.451-1.165-.124-.092-.252-.18-.365-.283-.436-.396-.48-.85-.135-1.318.296-.4.791-.508 1.307-.278.1.045.194.1.285.16 1.858 1.208 4.41 1.241 6.274.054.184-.134.382-.243.61-.299.446-.108.86.047 1.1.415.272.42.269.832-.067 1.158-.516.5-1.136.863-1.825 1.116-.652.239-1.365.36-2.071.439.106.11.157.164.223.227.96.912 1.922 1.82 2.878 2.734.326.312.394.698.214 1.06a1.144 1.144 0 01-1.065.629c-.272-.018-.485-.146-.674-.326-.724-.69-1.46-1.366-2.17-2.068-.206-.204-.305-.165-.487.012-.728.71-1.468 1.408-2.213 2.102-.334.312-.731.368-1.119.19-.412-.19-.674-.587-.654-.987.014-.27.155-.477.35-.663.95-.896 1.896-1.795 2.843-2.693.063-.06.121-.123.212-.216zm.605-3.163c-2.23-.008-4.057-1.86-4.045-4.098.014-2.263 1.843-4.086 4.094-4.08 2.256.006 4.066 1.856 4.055 4.142-.012 2.233-1.852 4.044-4.104 4.036zm2.025-4.093a1.99 1.99 0 00-1.993-1.997A1.992 1.992 0 009.82 8.11a1.99 1.99 0 002.008 1.982 1.985 1.985 0 001.983-2.006z" fill="currentColor"></path></svg>',
            },
            {
                link: 'https://twitter.com/tinkoff_bank',
                pic: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24"><path d="M20 7.538a6.553 6.553 0 01-1.885.517 3.297 3.297 0 001.443-1.815 6.616 6.616 0 01-2.086.797 3.283 3.283 0 00-5.592 2.993A9.318 9.318 0 015.115 6.6a3.259 3.259 0 00-.445 1.65c0 1.14.58 2.144 1.46 2.732a3.282 3.282 0 01-1.486-.412v.041a3.286 3.286 0 002.632 3.22 3.324 3.324 0 01-.864.115 3.15 3.15 0 01-.618-.061 3.284 3.284 0 003.065 2.28 6.584 6.584 0 01-4.076 1.403c-.265 0-.526-.016-.783-.045A9.276 9.276 0 009.031 19c6.038 0 9.338-5.002 9.338-9.34l-.01-.426A6.555 6.555 0 0020 7.538z" fill="currentColor"></path></svg>',
            },
            {
                link: 'https://www.youtube.com/user/tcsbank',
                pic: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24"><path d="M19.376 7.03C18.8 6.298 17.732 6 15.696 6H8.304C6.22 6 5.136 6.318 4.56 7.097 4 7.857 4 8.975 4 10.524v2.952c0 3 .666 4.524 4.304 4.524h7.392c1.766 0 2.745-.263 3.378-.91.649-.661.926-1.742.926-3.614v-2.952c0-1.633-.043-2.759-.624-3.494zm-5.104 5.378l-3.357 1.87a.49.49 0 01-.506-.018.559.559 0 01-.249-.47v-3.728c0-.192.094-.37.248-.47a.489.489 0 01.506-.018l3.357 1.858c.17.094.278.282.278.487a.556.556 0 01-.277.489z" fill="currentColor"></path></svg>',
            },
//      {
//        link: 'https://www.instagram.com/TinkoffBank/',
//        pic: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24"><path d="M9 5h6a4 4 0 014 4v6a4 4 0 01-4 4H9a4 4 0 01-4-4V9a4 4 0 014-4zm3 10a3 3 0 100-6 3 3 0 000 6zm3.444-5.556a.889.889 0 100-1.777.889.889 0 000 1.777z" fill="currentColor"></path></svg>',
//      },
            {
                link: 'https://t.me/tinkoffbank',
                pic: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24"><path d="M3.283 11.714l3.686 1.378 1.427 4.598a.434.434 0 00.69.208l2.055-1.678a.611.611 0 01.747-.021l3.707 2.696a.433.433 0 00.68-.263l2.716-13.086a.436.436 0 00-.582-.495L3.278 10.899a.436.436 0 00.005.815zm4.883.645l7.206-4.447c.129-.079.262.096.151.2L9.577 13.65a1.23 1.23 0 00-.382.738l-.203 1.504c-.027.201-.308.221-.364.027l-.779-2.743a.727.727 0 01.317-.817z" fill="currentColor"></path></svg>',
            },
        ],
    }),

    props: {
        phone: {},
        productLinks: {},
        bankLinks: {
            default: [
                {link: 'https://www.tinkoff.ru/about/', text: 'Тинькофф'},
                {link: 'https://www.tinkoff.ru/', text: 'Банк'},
                {link: 'https://www.tinkoff.ru/business/', text: 'Бизнес'},
                {link: 'https://www.tinkoff.ru/invest/', text: 'Инвестиции'},
                {link: 'https://www.tinkoff.ru/insurance/', text: 'Страхование'},
                {link: 'https://www.tinkoff.ru/mobile-operator/', text: 'Сим-карта'},
                {link: 'https://www.tinkoff.ru/travel/', text: 'Путешествия'},
                {link: 'https://www.tinkoff.ru/entertainment/', text: 'Развлечения'},
            ]
        },
        disclaimer: {
            default: 'АО «Тинькофф Банк», лицензия № 2673, юридический адрес: 127287, г. Москва, ул. Хуторская 2-я, д. 38А, стр. 26, почтовый адрес: а/я 23, г. Москва (далее — Банк). Годовая ставка по кредиту от 7,9% до 21,9 %. Сумма кредита — от 100 000 до 3 000 000<br>' +
                'рублей. Срок кредита — от 12 месяцев до 5 лет. Штраф за неуплату регулярного платежа — 0,1% от просроченной задолженности. Плата за невыполнение условий залога — 0,5% от первоначальной суммы кредита. Условия применимы к Тарифному плану КНА 7.0<br>' +
                '(рубли РФ). Банк оставляет за собой право самостоятельно по результатам рассмотрения заявки предложить иной тариф, а также отказать в выдаче кредита без объяснения причин.'
        },
        copyright: {
            default: 'АО «Тинькофф Банк», официальный сайт, лицензия ЦБ РФ № 2673<br>1-й Волоколамский проезд, д. 10, стр. 1, Москва, 123060 Россия'
        }
    },

    computed: {
        year() {
            return new Date().getFullYear()
        },
    }
}
</script>

<style scoped>
footer {
    background-color: #f5f5f6;
    padding-top: 1px;
}

.bottom {
    color: #909090;
    font-size: 13px;
    border-top: 1px solid #dddddd;
}

.phone {
    font-size: 26px;
    line-height: 28px;
    font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: -.3px;
    color: black;
}

.call-free {
    font-size: 13px;
    color: #909090;
}

.product-links {
    margin-top: 10px;
}

a {
    text-decoration: none;
    font-size: 13px;
    margin-right: 20px;
}

.tks-links a {
    color: #0000008a;
}

.tks-links a:hover {
    color: #000000;
}

.product-links a {
    color: #0050f1;
}

.product-links a:hover {
    color: #1f50ba;
}

.social a {
    margin-right: 0;
    margin-left: 20px;
    color: rgba(0, 0, 0, 0.4);
}

.social a:hover {
    color: rgba(0, 0, 0, 0.9);
}

.logo svg {
    margin-right: auto;
    margin-left: auto;
}

@media screen and (max-width: 575px) {
    .social a:first-of-type {
        margin-left: 0;
    }

    .links {
        border-bottom: 1px solid #f5f5f6;
        border-top: 1px solid #dddddd;
        background-color: #ffffff;
    }

}

</style>